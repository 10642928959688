<template>
    <div class="ai-container-mobile" ref="videoContainer">
        <!-- <div class="title">AI加持，效率再获提升</div> -->
        <div class="title">{{ t('global_home_ai_title') }}</div>
        <div class="content">
            <!-- 左边 -->
            <div class="top-desc">
                <AnimationAI :animation="loaded"></AnimationAI>
                <img :src="arrowLine" alt="" class="line" />
                <div class="middle">3D</div>
                <img :src="arrowLine" alt="" class="line" />
                <AnimationAI :animation="!loaded"></AnimationAI>
            </div>
            <!-- 右边 -->
            <div class="card-container">
                <div class="ai-card ai-card1">
                    <video
                        ref="leftVideoRef"
                        class="video"
                        :src="leftVideo"
                        muted
                        playsinline
                        preload="auto"
                        @ended="onLeftEnd"
                    ></video>
                    <div class="bottom-text">
                        <div class="title">{{ t('global_home_ai_left_title') }}</div>
                        <div class="desc">{{ t('global_home_ai_left_desc') }}</div>
                    </div>
                </div>
                <div class="ai-card ai-card2">
                    <img :src="aiImg" alt="" />
                    <div class="bottom-text"></div>
                </div>
                <div class="ai-card ai-card3">
                    <video
                        ref="rightVideoRef"
                        class="video"
                        :src="rightVideo"
                        muted
                        playsinline
                        preload="auto"
                        @ended="onRightEnd"
                    ></video>
                    <div class="bottom-text">
                        <div class="title">{{ t('global_home_ai_right_title') }}</div>
                        <div class="desc">{{ t('global_home_ai_right_desc') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { onMounted, reactive, ref } from 'vue';
    import aiImg from './ai.png';
    import lineImg from './line.png';
    import arrowLine from './arrowLine.png';
    import leftVideo from './leftVideo.mp4';
    import rightVideo from './rightVideo.mp4';
    import AnimationAI from '../../AnimationAI.vue';
    import i18n from '@/locales';

    const { t } = i18n.global;
    const leftVideoRef = ref(null);
    const rightVideoRef = ref(null);
    const loaded = ref(false);

    onMounted(() => {
        leftVideoRef.value.play();
        loaded.value = true;
        addSlideInUp('.ai-card');
        addSlideInUp('.ai-card1', { delay: 0.1 });
        addSlideInUp('.ai-card2', { delay: 0.2 });
        addSlideInUp('.ai-card3', { delay: 0.3 });
    });

    const onLeftEnd = () => {
        rightVideoRef.value.play();
        loaded.value = false;
    };
    const onRightEnd = () => {
        leftVideoRef.value.play();
        loaded.value = true;
    };
</script>
<style lang="less" scoped>
    :lang(en) {
        .ai-container-mobile {
            font-family: Montserrat;
            .title {
                font-weight: 500;
                width: 266px;
                text-align: center;
            }
        }
    }
    .ai-container-mobile {
        color: #fff;
        font-family: HarmonyOS Sans SC;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        width: 100%;
        .title {
            font-size: 24px;
            font-weight: 400;
            line-height: 28.13px;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }
        .top-desc {
            font-family: Montserrat;
            display: flex;
            align-items: center;
            width: 24px;
            margin-right: 15px;
            margin-top: -30px;
            flex-direction: column;

            .middle {
                font-size: 16px;
                color: #ababab;
            }
            .line {
                width: 8px;
                height: 210px;
            }
            :deep(.comp-animation-ai) {
                width: 24px;
                height: 27px;
                .ai-text {
                    font-size: 20px;
                }
            }
        }

        .card-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .ai-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &:nth-child(2) {
                    margin-top: 42px;
                }
                &:nth-child(3) {
                    margin-top: 42px;
                }
                .video {
                    width: 302px;
                }
                img {
                    width: 302px;
                }

                .bottom-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 10px;
                    .title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 18.75px;
                    }
                    .desc {
                        width: 205px;
                        text-align: center;
                        margin-top: 5px;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 11.72px;
                        color: #979797;
                    }
                }
            }
        }
    }
</style>
