<template>
    <div class="procedure-container">
        <!-- 标题 -->
        <div class="procedure-title">
            <!-- <div class="title">全链路赋能时尚产业</div>
            <div class="desc">降低实物样衣损耗，提升设计效率，<br />让决策更高效</div> -->
            <div class="title">{{ t('global_home_procedure_title') }}</div>
            <div class="desc" v-html="t('global_home_procedure_desc_mobile')"></div>
        </div>
        <!--  -->
        <CommonComponentsTimeLine>
            <template #content>
                <div class="card-container">
                    <div
                        class="procedure-card"
                        :class="`procedure-card-${itemIndex}`"
                        v-for="(item, itemIndex) in procedureList"
                        :key="itemIndex"
                    >
                        <img :src="item.img" alt="" />
                        <div class="card-text">
                            <div class="title1">{{ item.title }}</div>
                            <div class="desc1">
                                {{ item.desc }}
                            </div>
                            <div
                                class="card-link"
                                :class="`card-link-${iIndex}`"
                                v-if="!isCN"
                                v-for="(item, iIndex) in procedureList[itemIndex].linkName"
                                :key="iIndex"
                                @click="linkHref(item.routePath)"
                            >
                                <div class="name">
                                    {{ item.name }}
                                </div>
                                <img :src="LinkRight" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CommonComponentsTimeLine>
    </div>
</template>

<script lang="ts" setup>
    import { onMounted, reactive, ref } from 'vue';
    import Card1Img from './Card1.png';
    import Card2Img from './Card2.png';
    import Card3Img from './Card3.png';
    import Card4Img from './Card4.png';
    import Card5Img from './Card5.png';
    import LinkRight from './LinkRight.png';
    import i18n from '@/locales';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { pushRouter } from '~~/composables/useRouterController';

    const isCN = ifCNLocale();
    const { t } = i18n.global;
    const curTimelineIndex = ref(0);
    const linkHref = (routePath) => {
        pushRouter(routePath);
    };
    const procedureList = reactive([
        {
            img: Card1Img,
            title: t('global_home_procedure_card1_title'),
            desc: t('global_home_procedure_card1_desc_mobile'),
            linkName: [
                {
                    name: 'Style3D Ai',
                    routePath: '/products/ai',
                },
            ],
        },
        {
            img: Card2Img,
            title: t('global_home_procedure_card2_title'),
            desc: t('global_home_procedure_card2_desc_mobile'),
            linkName: [
                {
                    name: 'Style3D Studio',
                    routePath: '/products/studio',
                },
                {
                    name: 'Style3D Fabric',
                    routePath: '/products/fabric',
                },
            ],
        },
        {
            img: Card3Img,
            title: t('global_home_procedure_card3_title'),
            desc: t('global_home_procedure_card3_desc_mobile'),
            linkName: [
                {
                    name: 'Style3D Cloud',
                    routePath: '/products/cloud',
                },
            ],
        },
        {
            img: Card5Img,
            title: t('global_home_procedure_card5_title'),
            desc: t('global_home_procedure_card5_desc_mobile'),
            linkName: [
                {
                    name: 'Style3D Cloud',
                    routePath: '/products/cloud',
                },
            ],
        },
        {
            img: Card4Img,
            title: t('global_home_procedure_card4_title'),
            desc: t('global_home_procedure_card4_desc_mobile'),
            linkName: [
                {
                    name: 'Style3D Ai',
                    routePath: '/products/ai',
                },
            ],
        },
    ]);
</script>

<style lang="less" scoped>
    :lang(en) {
        font-family: Montserrat;
        .procedure-title {
            .title {
                font-weight: 500;
            }
            .desc {
                font-weight: 500;
            }
        }
        .card-container {
            .procedure-card {
                .card-text {
                    .title1 {
                        font-family: Montserrat;
                    }
                    .desc1 {
                        font-weight: 500;
                        font-family: 'Montserrat';
                        color: #979797;
                        margin-bottom: 24px;
                    }
                    .card-link {
                        margin-top: 10px;
                        padding: 14px;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #18181a;
                        border-radius: 8px;
                        cursor: pointer;
                        .name {
                            font-size: 14px;
                            font-weight: 500;
                        }
                        img {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }
    .procedure-container {
        :deep(.default-timeline-wrapper) {
            margin-top: 30px;
            .timeline-content-container {
                gap: 0;
            }
            .default-timeline-progress-container {
                .default-timeline-progress-blur {
                    width: 0;
                }
            }
        }
    }
    .procedure-title {
        margin-top: 60px;
        color: #fff;
        font-family: HarmonyOS Sans SC;
        .title {
            font-size: 24px;
            font-weight: 400;
            line-height: 28.13px;
            text-align: center;
        }
        .desc {
            margin-top: 10px;
            opacity: 0.6;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.41px;
            text-align: center;
        }
    }
    .card-container {
        display: flex;
        flex-direction: column;

        .procedure-card {
            margin-top: 30px;
            width: 302px;
            border-radius: 20px;
            position: relative;
            &:nth-child(1) {
                margin-top: 0px;
            }
            img {
                width: 302px;
            }
            .card-text {
                padding: 20px;
                background: #252528;
                border-radius: 0 0 20px 20px;
                .title1 {
                    font-family: HarmonyOS Sans SC;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 23.44px;
                    text-align: left;
                    color: #fff;
                }
                .desc1 {
                    font-family: HarmonyOS Sans SC;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    text-align: left;
                    color: #979797;
                    margin-top: 10px;
                }
            }
        }
    }
</style>
