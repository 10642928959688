<script lang="ts" setup>
    import img1 from './img1.png';
    import img2 from './img2.png';
    import img3 from './img3.png';
    import img1M from './img1Mobile.png';
    import img2M from './img2Mobile.png';
    import img3M from './img3Mobile.png';
    import { useRouter } from 'vue-router';
    import { getIsMobileRef } from '~~/composables/useMatchRules.ts';

    const isMobile = getIsMobileRef();
    const router = useRouter();
    const toPartners = () => {
        router.push({ path: '/aboutus/partners' });
    };
    const toConsultationservice = () => {
        router.push({ path: '/support/consultationservice' });
    };
    // const toAi = () => {
    //     router.push({ path: '/products/ai' });
    // };
</script>
<template>
    <div :class="{ 'advantage-container': !isMobile, 'advantage-container-mobile': isMobile }">
        <div class="title">Why Style3D?</div>
        <div class="card-container">
            <div class="default">
                <img :src="img1" v-if="!isMobile" />
                <img :src="img1M" v-else />
                <div class="text-container">
                    <div class="title">Cutting-edge Research in 3D and AI</div>
                    <div class="desc">
                        Our research findings in 3D and AI are integrated into Style3D products and published at
                        prestigious conferences such as SIGGRAPH, NeurIPS and CVPR.
                    </div>
                    <div class="button exhibit">
                        <CommonComponentsButtonLine borderless button-text="Learn More" disabled="true" />
                    </div>
                </div>
            </div>
            <div class="default reverse">
                <img :src="img2M" v-if="isMobile" />
                <div class="text-container">
                    <div class="title">A Digitalization Ecosystem that Benefits Us All</div>
                    <div class="desc">
                        Style3D fosters rapid, high-standard global collaboration among thousands of manufacturers,
                        suppliers, and service providers.
                    </div>
                    <div class="button">
                        <CommonComponentsButtonLine borderless button-text="Learn More" @click="toPartners" />
                    </div>
                </div>
                <img :src="img2" v-if="!isMobile" />
            </div>
            <div class="default">
                <img :src="img3" v-if="!isMobile" />
                <img :src="img3M" v-else />
                <div class="text-container">
                    <div class="title">Global Team Providing Expert Support</div>
                    <div class="desc">
                        Our team, located across four continents, provides high-standard consultation, training,
                        and digital product services, available anytime, anywhere.
                    </div>
                    <div class="button">
                        <CommonComponentsButtonLine
                            borderless
                            button-text="Learn More"
                            @click="toConsultationservice"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .advantage-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Montserrat;
        color: #fff;
        font-weight: 500;
        margin-top: 60px;
        width: 100%;
        .title {
            font-size: 21px;
            font-weight: 500;
            line-height: 25.6px;
            text-align: center;
        }
        .card-container {
            .default {
                margin-top: 35px;
                display: flex;
                flex-direction: column;
                img {
                    width: 332px;
                }
                .text-container {
                    width: 100%;
                    margin-left: 0px;
                    .title {
                        text-align: left;
                        margin-top: 20px;
                        font-size: 20px;
                        width: 280px;
                        line-height: 24.38px;
                    }
                    .desc {
                        font-family: Montserrat;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 14px;
                        color: #c8c8c8;
                        width: 332px;
                        margin-top: 8px;
                        margin-bottom: 12px;
                    }
                    .exhibit {
                        :deep(.button-container) {
                            background-color: #1c1c1c;
                        }
                    }
                    .button {
                        :deep(.button-container) {
                            width: 98px;
                            height: 32px;
                            .button-flex-container {
                                .button-text {
                                    font-size: 10px;
                                    font-weight: 500;
                                }
                            }
                        }
                        :deep(.borderless) {
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }
    }
    .advantage-container {
        margin-top: 120px;
        font-family: Montserrat;
        color: #fff;
        font-weight: 500;
        .title {
            font-size: 48px;
            line-height: 58.51px;
            text-align: center;
        }
        .card-container {
            margin-top: 80px;
            .default {
                display: flex;
                align-items: center;
                margin-top: 100px;
                img {
                    width: 620px;
                }
                .text-container {
                    margin-left: 62px;
                    .title {
                        width: 459px;
                        font-size: 38px;
                        line-height: 46.32px;
                        text-align: left;
                    }
                    .desc {
                        width: 435px;
                        font-size: 18px;
                        line-height: 21.94px;
                        text-align: left;
                        margin-top: 20px;
                        margin-bottom: 28px;
                    }
                    .button {
                        // width: 160px;
                        // height: 52px;
                    }
                    .exhibit {
                        :deep(.button-container) {
                            background: #1c1c1c;
                            .button-hover-mask {
                                display: none;
                            }
                        }
                        :deep(.borderless) {
                            box-shadow: none !important;
                        }
                    }
                }
            }
            .reverse {
                justify-content: end;
                img {
                    margin-left: .convert(180px) [ @vw];
                }
            }
        }
    }
</style>
