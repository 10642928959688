<template>
    <div class="partners-container">
        <div class="title">{{ t('global_home_partners_title') }}</div>
        <div class="img-container" v-if="!isCN"><img :src="EnImg" /></div>
        <div class="slide-container" v-else>
            <a-carousel arrows :dots="false">
                <template #prevArrow>
                    <div class="custom-slick-arrow" style="z-index: 1">
                        <div class="arrow left-arrow">
                            <img :src="LeftArrow" alt="" />
                        </div>
                    </div>
                </template>
                <template #nextArrow>
                    <div class="custom-slick-arrow right" style="right: 0px">
                        <div class="arrow right-arrow">
                            <img :src="RightArrow" alt="" />
                        </div>
                    </div>
                </template>
                <div class="image-wrapper">
                    <img :src="LeftImg" alt="" />
                </div>
                <div class="image-wrapper">
                    <img :src="RightImg" alt="" />
                </div>
            </a-carousel>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import LeftImg from './Left.png';
    import RightImg from './Right.png';
    import EnImg from './PartnersEn.png';
    import LeftArrow from './LeftArrow.png';
    import RightArrow from './RightArrow.png';
    import i18n from '@/locales';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    const isCN = ifCNLocale();
    const { t } = i18n.global;
</script>
<style lang="less" scoped>
    :lang(en) {
        .partners-container {
            margin-top: 160px;
            .title {
                font-size: 48px;
                font-weight: 500;
                font-family: Montserrat;
            }
        }
    }
    .partners-container {
        color: #fff;
        margin-top: 100px;
        margin-bottom: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'HarmonyOS Sans SC';
        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 56.26px;
        }

        .img-container {
            width: 1042px;
            margin-top: 80px;
            img {
                width: 100%;
            }
        }
        .slide-container {
            margin-top: 80px;
            width: 1280px;

            .ant-carousel {
                .image-wrapper {
                    width: 1280px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 130px;
                    img {
                        width: 1016px;
                    }
                }
            }

            .custom-slick-arrow {
                .arrow {
                    width: 44px;
                    height: 44px;
                    background: #626262;
                    border-radius: 90px;
                    margin-top: -11px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 24px;
                        opacity: 0.6;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
</style>
