<template>
    <div class="ai-container" ref="videoContainer">
        <div class="title">{{ t('global_home_ai_title') }}</div>
        <div class="top-desc">
            <AnimationAI :animation="loaded"></AnimationAI>
            <img :src="arrowLine" alt="" class="line" />
            <div class="middle">3D</div>
            <img :src="arrowLine" alt="" class="line" />
            <AnimationAI :animation="!loaded"></AnimationAI>
        </div>
        <div class="card-container">
            <div class="ai-card ai-card1">
                <video
                    ref="leftVideoRef"
                    class="video"
                    :src="leftVideoSrc"
                    muted
                    playsinline
                    preload="auto"
                    @ended="onLeftEnd"
                ></video>
                <div class="bottom-text">
                    <div class="title">{{ t('global_home_ai_left_title') }}</div>
                    <div class="desc">{{ t('global_home_ai_left_desc') }}</div>
                </div>
            </div>
            <div class="ai-card ai-card2">
                <img :src="aiImg" alt="" />
                <div class="bottom-text"></div>
            </div>
            <div class="ai-card ai-card3">
                <video
                    ref="rightVideoRef"
                    class="video"
                    :src="rightVideoSrc"
                    muted
                    playsinline
                    preload="auto"
                    @ended="onRightEnd"
                ></video>
                <div class="bottom-text">
                    <div class="title">{{ t('global_home_ai_right_title') }}</div>
                    <div class="desc" v-html="t('global_home_ai_right_desc')"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { onMounted, reactive, ref } from 'vue';
    import aiImg from './ai.png';
    import lineImg from './line.png';
    import arrowLine from './arrowLine.png';
    import leftVideo from './leftVideo.mp4';
    import rightVideo from './rightVideo.mp4';
    import leftEnVideo from './leftEn.mp4';
    import rightEnVideo from './rightEn.mp4';
    import AnimationAI from '../../AnimationAI.vue';
    import i18n from '@/locales';
    import { ifCNLocale } from '~~/composables/useLocale.ts';

    const isCN = ifCNLocale();
    const { t } = i18n.global;
    const leftVideoRef = ref(null);
    const rightVideoRef = ref(null);
    const loaded = ref(false);

    onMounted(() => {
        leftVideoRef.value.play();
        loaded.value = true;
        addSlideInUp('.ai-card');
        addSlideInUp('.ai-card1', { delay: 0.1 });
        addSlideInUp('.ai-card2', { delay: 0.2 });
        addSlideInUp('.ai-card3', { delay: 0.3 });
    });
    const leftVideoSrc = isCN ? leftVideo : leftEnVideo;
    const rightVideoSrc = isCN ? rightVideo : rightEnVideo;
    const onLeftEnd = () => {
        rightVideoRef.value.play();
        loaded.value = false;
    };
    const onRightEnd = () => {
        leftVideoRef.value.play();
        loaded.value = true;
    };
</script>
<style lang="less" scoped>
    :lang(en) {
        .ai-container {
            font-family: Montserrat;
            .title {
                font-weight: 500;
            }
            .ai-card {
                .title {
                    font-weight: 400;
                }
                .desc {
                    font-size: 14px;
                    width: 286px;
                    text-align: center;
                }
            }
        }
    }
    .ai-container {
        color: #fff;
        font-family: HarmonyOS Sans SC;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 56.26px;
        }

        .top-desc {
            font-family: Montserrat;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 940px;
            margin-top: 40px;
            .middle {
                font-size: 24px;
                color: #ababab;
            }
            .line {
                width: 370px;
            }
        }

        .card-container {
            width: 1280px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .ai-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .video {
                width: 377px;
            }
            img {
                width: 334px;
            }

            .bottom-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 76px;
                margin-top: 30px;
            }

            .title {
                font-size: 24px;
                font-weight: 500;
                line-height: 28.13px;
            }
            .desc {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 400;
                line-height: 18.75px;
                color: #979797;
            }
        }
    }
</style>
