<template>
    <div class="mobil-cn-partners-container">
        <div class="title">{{ t('global_home_partners_title') }}</div>
        <div class="partners-img">
            <img class="cn" :src="Partner" alt="" v-if="isCN" />
            <img class="en" :src="PartnerEn" alt="" v-else />
        </div>
    </div>
</template>
<script lang="ts" setup>
    import Partner from './partner.png';
    import PartnerEn from './partnerEn.png';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import i18n from '@/locales';
    const { t } = i18n.global;
    const isCN = ifCNLocale();
</script>
<style lang="less" scoped>
    :lang(en) {
        .mobil-cn-partners-container {
            .title {
                font-family: Montserrat;
                font-weight: 500;
            }
        }
    }
    .mobil-cn-partners-container {
        width: 100%;

        margin-top: 60px;
        margin-bottom: 60px;
        .title {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-family: HarmonyOS Sans SC;
            font-size: 21px;
            font-weight: 400;
            line-height: 24.61px;
            color: #fff;
        }
        .partners-img {
            width: 100vw;
            padding: 0 0 0 10px;
            margin-top: 30px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
            .cn {
                width: 1000px;
            }
            .en {
                width: 332px;
            }
        }
    }
</style>
