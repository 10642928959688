<template>
    <div class="product-container">
        <div class="product-title">3D核心产品</div>
        <div class="inner-container">
            <!-- left -->
            <div class="left-container">
                <div class="details-item" v-for="(item, itemIndex) in products" :key="itemIndex">
                    <div :class="['content-container', { 'expand-container': expandIndex === itemIndex }]">
                        <div class="small-desc" @click="click(itemIndex)">{{ item.smalldesc }}</div>
                        <div class="title" @click="click(itemIndex)">{{ item.title }}</div>
                        <div
                            :class="[{ 'no-add': expandIndex === itemIndex }, { add: expandIndex !== itemIndex }]"
                            @click="click(itemIndex)"
                        >
                            <img :src="AddImage" alt="" />
                        </div>
                        <div
                            :class="[{ fold: expandIndex === itemIndex }, { 'no-fold': expandIndex !== itemIndex }]"
                            @click="click(itemIndex)"
                        >
                            <img :src="ReduceImage" alt="" />
                        </div>
                        <!-- 折叠区域 -->
                        <div class="desc" v-html="item.desc"></div>
                        <div class="arrow" @click="pushRouter(item.to)">
                            <img :src="ArrowImage" alt="" />
                        </div>
                    </div>
                    <div class="line" :class="{ end: itemIndex === products.length - 1 }"></div>
                </div>
            </div>
            <!-- right -->
            <div class="right-container">
                <div v-for="(item, itemIndex) in products" :key="itemIndex" v-show="itemIndex === expandIndex">
                    <!-- <img :src="item.img" alt="" /> -->
                    <!-- <div>1234</div> -->
                    <CommonComponentsVideoPlayer
                        :id="`home-details-media-video-${itemIndex}`"
                        class="home-details-media-video"
                        :sources="[
                            {
                                src: item.videoPath,
                                type: 'video/mp4',
                            },
                        ]"
                        :poster="item.poster"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { ref, reactive } from 'vue';
    import AddImage from './add.png';
    import ReduceImage from './reduce.png';
    import ArrowImage from './arrow.png';
    import detailStudio from '@assets/Video/home/detail_studio.mp4';
    import detailFabric from '@assets/Video/home/detail_fabric.mp4';
    import detailCloud from '@assets/Video/home/detail_cloud.mp4';
    import detailMixMatch from '@assets/Video/home/detail_mixmatch.mp4';
    import imgMixMatch from '@assets/Video/home/detail_mixmatch.png';
    // import imgCloud from '@assets/Video/home/detail_cloud.jgp';
    import imgFabric from '@assets/Video/home/detail_fabric.jpg';
    import imgStudio from '@assets/Video/home/detail_studio.png';

    import { pushRouter } from '~~/composables/useRouterController';
    import { routeMapper } from '~~/composables/useRoutesMapper';

    const expandIndex = ref(0);

    const click = (index) => {
        expandIndex.value = index;
    };

    const products = reactive([
        {
            smalldesc: '3D服装仿真建模软件',
            title: 'Style3D Studio',
            desc: '款式设计研发，代替实物打版制衣。<br>融合AI，服装、模特还原更真实。<br>与多种打版制版软件联动，轻松快捷。',
            videoPath: detailStudio,
            to: routeMapper.products.studio,
            poster: imgStudio,
        },
        {
            smalldesc: '数字面料创作软件',
            title: 'Style3D Fabric',
            desc: '快速编辑、调整面料，预览上身效果。<br>联动硬件扫描测量、精准创作面料。<br>上传云端，沉淀资产，助力多方协同。',
            videoPath: detailFabric,
            to: routeMapper.products.fabric,
            poster: imgFabric,
        },
        {
            smalldesc: '3D服装实时搭配软件',
            title: 'Style3D MixMatch',
            desc: '服装快速搭配，设计搭配效率提升。<br>订货发布，结构分析组货，提前评估。<br>3D搭配试穿高效灵活，提高连带率。',
            videoPath: detailMixMatch,
            to: routeMapper.products.mixmatch,
            poster: imgMixMatch,
        },
        {
            smalldesc: '数字资产云协同平台',
            title: 'Style3D Cloud',
            desc: '在线货盘看款，内外部高效协同决策。<br>数字资产沉淀，可随时管理分享。<br>在线推款、数字展厅等工具，生意拓展。',
            videoPath: detailCloud,
            to: routeMapper.products.cloud,
            // poster: imgCloud,
        },
    ]);
</script>
<style lang="less" scoped>
    .product-container {
        color: #ffff;
        font-family: HarmonyOS Sans SC;
        margin-top: 80px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .product-title {
            font-size: 48px;
            margin-bottom: 40px;
            text-align: center;
            font-weight: 400;
        }
    }
    .inner-container {
        background: #242427;
        border-radius: 40px;
        padding: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .left-container {
            width: 420px;
            background-color: #242427;
            position: relative;

            .details-item {
                .content-container {
                    height: 54px;
                    overflow: hidden;
                    transition: height 0.3s ease-in-out;

                    .small-desc {
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.6);
                        line-height: 16.41px;
                        cursor: pointer;
                        letter-spacing: 0.8px;
                    }
                    .title {
                        font-family: Montserrat;
                        font-size: 40px;
                        font-weight: 500;
                        line-height: 40px;
                        margin-top: 5px;
                        cursor: pointer;
                    }
                    .no-add {
                        display: none;
                    }
                    .add {
                        position: absolute;
                        right: 10px;
                        margin-top: -60px;
                        cursor: pointer;
                        img {
                            width: 20px;
                        }
                    }
                    .no-fold {
                        display: none;
                    }
                    .fold {
                        position: absolute;
                        right: 10px;
                        margin-top: -60px;
                        img {
                            width: 20px;
                        }
                    }

                    .desc {
                        color: #c6c6c8;
                        font-size: 16px;
                        margin-top: 15px;
                        width: 350px;
                        letter-spacing: 0.8px;
                        font-weight: 350;
                    }
                    .arrow {
                        margin-top: 30px;
                        margin-bottom: 49px;
                        width: 72px;
                        height: 32px;
                        background: #ffffff;
                        border-radius: 83px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 24px;
                        }
                    }
                }

                .expand-container {
                    height: 253px;
                }

                .line {
                    width: 420px;
                    border-bottom: 1px solid #fff;
                    margin-bottom: 26px;
                    &.end {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .right-container {
            width: .convert(680px) [ @vw];
            margin-left: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            div {
                width: 100%;
                height: 100%;
                min-width: .convert(680px) [ @vw];
            }
        }
    }
</style>
