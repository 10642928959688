<script setup lang="ts">
    import { routeMapper } from '~~/composables/useRoutesMapper';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { ref, reactive, onMounted, onBeforeMount, computed } from 'vue';
    import homeSwipperVideo from '@assets/Video/home/new_first_screen.mp4';

    import i18n from '@/locales';

    const isCN = ifCNLocale();
    const { t } = i18n.global;
    const isMobile = ref(false);
    const topVisible = ref(false);

    const showVideoSwipper = ref(false);

    onMounted(() => {
        isMobile.value = matchMobile();

        showVideoSwipper.value = true;

        window.addEventListener('scroll', appearBackTop);

        console.log('onMounted', 'HomeMobileCn');
    });

    const appearBackTop = () => {
        // console.log('window.scrollY', window.scrollY);
        // console.log('window.innerHeight', window.innerHeight * 2);
        if (window.scrollY >= window.innerHeight * 2) {
            // console.log('topVisible.value = true;', (topVisible.value = true));
            topVisible.value = true;
        }
        if (window.scrollY < window.innerHeight * 2) {
            // console.log('topVisible.value = true;', (topVisible.value = true));
            topVisible.value = false;
        }
    };
</script>

<template>
    <div class="home-mobile-container">
        <!-- 首页轮播 -->
        <div class="home-ant-carousel">
            <video :src="homeSwipperVideo" muted loop playsinline autoplay preload="auto"></video>
            <div class="home-swipper-mask"></div>
            <div class="text-wrapper" v-if="showVideoSwipper">
                <div class="text-content global-limited-1360px-container">
                    <div class="title" v-html="t('global_home_swipper_text_mobile')"></div>
                    <div class="subTitle" v-html="t('global_home_swipper_subtext_mobile')"></div>
                </div>
            </div>
        </div>
        <div class="global-limited-1360px-container">
            <!-- 全链路 -->
            <CommonComponentsHomeMobileProcedure />
            <!-- 产品目录 -->
            <CommonComponentsHomeProductMobileCnDetails v-if="isCN" />
            <!-- AI -->
            <CommonComponentsHomeMobileAI />
            <!-- 地图 -->
            <CommonComponentsHomeMobileCnMapLink v-if="isCN" />
            <!-- 优势 -->
            <CommonComponentsHomeNewAdvantages v-if="!isCN" />
            <!-- 合作伙伴 -->
            <CommonComponentsHomeMobilePartners />
        </div>
        <div class="gsap-pin-target">
            <CommonComponentsFooter />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .home-mobile-container {
        background-color: #000000;
        width: 100%;
        .home-ant-carousel {
            position: relative;
            height: 78vh;
            video {
                height: 78vh;
                width: 100%;
                object-fit: cover;
                // 隐藏播放等按钮
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                outline: none;
                border: none;
                background: transparent;
                background-color: transparent;
            }
            video::-webkit-media-controls {
                display: none !important;
            }
            video::-webkit-media-controls-enclosure {
                display: none !important;
            }
            .home-swipper-mask {
                z-index: 1;
                position: absolute;
                top: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
            }
            .text-wrapper {
                z-index: 2;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 100px;
            }
            .text-content {
                font-family: 'HarmonyOS Sans SC';
                color: #fff;
                .title {
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 35.13px;
                    color: #fff;
                    filter: drop-shadow(0 0 1rem #00000022);
                }
                .subTitle {
                    width: 310px;
                    margin-top: 16px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22.4px;
                    filter: drop-shadow(0 0 1rem #00000022);
                }
            }
        }
    }
    :lang(en) {
        .home-ant-carousel {
            .text-content {
                font-family: 'Montserrat';
                .title {
                    font-size: 24px;
                    font-weight: 500;
                }
                .subTitle {
                    width: auto;
                    font-weight: 500;
                }
            }
        }
    }
</style>
<style lang="less">
    .bg-light-gray {
        overflow: hidden;
        background-color: #262626;
        padding-bottom: .convert(100px) [ @vh];
        @media (min-width: 1440px) {
            padding-bottom: .convert(30px) [ @vh];
        }
        @media (min-width: 1920px) {
            padding-bottom: .convert(10px) [ @vh];
        }
    }

    .solution-arrow-up,
    .solution-arrow-down {
        margin: .convert(100px) [ @vw] 0;
        height: .convert(194px) [ @vw];
        @media (max-width: @screen-mobile-width) {
            margin: 40px 0;
            height: 50px;
        }
    }
</style>
