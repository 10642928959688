<script lang="ts" setup>
    import aiTextBg from '@assets/imgs/aiTextBg.png';

    const props = withDefaults(
        defineProps<{
            animation?: boolean;
        }>(),
        { animation: true },
    );
</script>

<template>
    <div class="comp-animation-ai" :class="{ animation: props.animation }">
        <span class="ai-text">AI</span>
        <img :src="aiTextBg" />
    </div>
</template>

<style lang="less" scoped>
    .comp-animation-ai {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        position: relative;
        .ai-text {
            font-size: 32px;
            font-weight: 500;
        }
        img {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
        }

        &.animation {
            img {
                animation: rotate 3s linear infinite; /* 应用动画 */
            }
        }
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
