<template>
    <div class="procedure-container">
        <!-- 标题 -->
        <div class="procedure-title">
            <template v-if="showTextVisible">
                <div class="title">{{ t('global_home_procedure_title') }}</div>
                <div class="desc">{{ t('global_home_procedure_desc') }}</div>
            </template>
        </div>
        <!-- 箭头 -->
        <div class="procedure-arrow">
            <div class="arrow" @click="toRight">
                <img :src="LeftArrow" alt="" />
            </div>
            <div class="arrow right" @click="toLeft">
                <img :src="RightArrow" alt="" />
            </div>
        </div>
        <div id="slide-container" class="slide-container">
            <!-- 链路 -->
            <div class="procedure-line padding-h">
                <img :src="LineImg" alt="" />
            </div>
            <!-- 卡片 -->
            <div class="padding-h">
                <div class="card-container">
                    <div
                        class="procedure-card"
                        :class="`procedure-card-${itemIndex}`"
                        v-for="(item, itemIndex) in procedureList"
                        :key="itemIndex"
                    >
                        <img :src="item.img" alt="" />
                        <div class="card-text">
                            <div class="title1">{{ item.title }}</div>
                            <div class="desc1" :class="`desc1-${itemIndex}`">
                                {{ item.desc }}
                            </div>
                            <div
                                class="card-link"
                                :class="`card-link-${iIndex}`"
                                v-if="!isCN"
                                v-for="(item, iIndex) in procedureList[itemIndex].linkName"
                                :key="iIndex"
                                @click="linkHref(item.routePath)"
                            >
                                <div class="name">
                                    {{ item.name }}
                                </div>
                                <img :src="LinkRight" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { onMounted, reactive, ref } from 'vue';
    import CardLineImg from './CardLine.png';
    import ColorLineImg from './ColorLine.png';
    import LinkRight from './LinkRight.png';
    import Card1Img from './Card1.png';
    import Card2Img from './Card2.png';
    import Card3Img from './Card3.png';
    import Card4Img from './Card4.png';
    import Card5Img from './Card5.png';
    import LeftArrow from './LeftArrow.png';
    import RightArrow from './RightArrow.png';
    import { addSlideInDown, addSlideInUp, addFadeIn } from '~~/composables/useScrollTrigger';
    import i18n from '@/locales';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { pushRouter } from '~~/composables/useRouterController';

    const isCN = ifCNLocale();
    const { t } = i18n.global;
    let slideContainer;
    const showTextVisible = ref(false);

    onMounted(() => {
        showTextVisible.value = true;
        slideContainer = document.getElementById('slide-container');
        addSlideInUp('.procedure-card-0', { delay: 0.1 });
        addSlideInUp('.procedure-card-1', { delay: 0.2 });
        addSlideInUp('.procedure-card-2', { delay: 0.3 });
        addSlideInUp('.procedure-card-3', { delay: 0.4 });
        addSlideInUp('.procedure-card-4', { delay: 0.5 });
    });
    const toRight = () => {
        slideContainer.scrollTo({
            left: -1100,
            behavior: 'smooth',
        });
    };
    const toLeft = () => {
        slideContainer.scrollTo({
            left: 1100,
            behavior: 'smooth',
        });
    };
    const linkHref = (routePath) => {
        pushRouter(routePath);
    };
    const LineImg = isCN ? CardLineImg : ColorLineImg;
    const procedureList = reactive([
        {
            img: Card1Img,
            title: t('global_home_procedure_card1_title'),
            desc: t('global_home_procedure_card1_desc'),
            linkName: [
                {
                    name: 'Style3D Ai',
                    routePath: '/products/ai',
                },
            ],
        },
        {
            img: Card2Img,
            title: t('global_home_procedure_card2_title'),
            desc: t('global_home_procedure_card2_desc'),
            linkName: [
                {
                    name: 'Style3D Studio',
                    routePath: '/products/studio',
                },
                {
                    name: 'Style3D Fabric',
                    routePath: '/products/fabric',
                },
            ],
        },
        {
            img: Card3Img,
            title: t('global_home_procedure_card3_title'),
            desc: t('global_home_procedure_card3_desc'),
            linkName: [
                {
                    name: 'Style3D Cloud',
                    routePath: '/products/cloud',
                },
            ],
        },
        {
            img: Card5Img,
            title: t('global_home_procedure_card5_title'),
            desc: t('global_home_procedure_card5_desc'),
            linkName: [
                {
                    name: 'Style3D Cloud',
                    routePath: '/products/cloud',
                },
            ],
        },
        {
            img: Card4Img,
            title: t('global_home_procedure_card4_title'),
            desc: t('global_home_procedure_card4_desc'),
            linkName: [
                {
                    name: 'Style3D Ai',
                    routePath: '/products/ai',
                },
            ],
        },
    ]);
</script>
<style lang="less" scoped>
    .procedure-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: HarmonyOS Sans SC;
        margin-top: 80px;
        width: 100%;
        overflow: hidden;
    }
    :lang(en) {
        font-family: Montserrat;
        .procedure-title {
            .title {
                font-weight: 500;
            }
            .desc {
                font-weight: 500;
            }
        }
        .slide-container {
            .padding-h {
                .card-container {
                    .procedure-card {
                        .card-text {
                            .desc1 {
                                height: auto;
                                font-weight: 400;
                                color: #fff;
                                margin-bottom: 30px;
                            }
                            .desc1-0 {
                                margin-bottom: 56px;
                            }
                            .desc1-4 {
                                margin-bottom: 56px;
                            }
                            .card-link {
                                margin-top: 12px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background: #18181a;
                                padding: 20px;
                                border-radius: 10px;
                                cursor: pointer;

                                &:hover {
                                    background: #111112;
                                }
                                .name {
                                    font-size: 18px;
                                    font-weight: 500;
                                }
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .procedure-title {
        min-height: 100px;
        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 56.26px;
            text-align: center;
        }
        .desc {
            font-size: 24px;
            font-weight: 400;
            line-height: 28.13px;
            text-align: center;
            color: #ffffff99;
            margin-top: 20px;
        }
    }
    .procedure-arrow {
        display: flex;
        margin-top: 40px;
        margin-bottom: 40px;
        .arrow {
            width: 44px;
            height: 44px;
            background-color: #626262;
            border-radius: 90px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
        }
        .right {
            margin-left: 20px;
        }
    }
    .slide-container {
        width: 100%;
        overflow-x: auto;
        // margin-top: 40px;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .padding-h {
        display: inline-block;
        padding-left: calc((100vw - 1360px) / 2);
        padding-right: calc((100vw - 1360px) / 2);
    }
    .procedure-line {
        img {
            width: 1984px;
        }
    }

    .card-container {
        display: flex;

        .procedure-card:last-child {
            margin-right: 0px;
        }
        .procedure-card {
            margin-right: 90px;
            border-radius: 30px;
            position: relative;

            &:nth-child(2n + 1) {
                margin-top: 80px;
            }
            img {
                width: 400px;
            }
            .card-text {
                padding: 30px;
                background: #252528;
                border-radius: 0 0 30px 30px;
                .title1 {
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 42.19px;
                }
                .desc1 {
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 24px;
                    color: #979797;
                    margin-top: 10px;
                    height: 72px;
                }
            }
        }
    }
</style>
